import React from 'react';

const ContactUs = () => {
    return (
        <div style={styles.container} id="contact-us">
            <h2 style={styles.heading}>Contact Us</h2>
            <p style={styles.text}>
                If you have any inquiries, reach out to us at
                <span style={styles.inlineImage}>
                    {/* Replace with the actual image tag when available */}
                    <img 
                        src="/pictures/hello.webp" 
                        alt="Email Address" 
                        style={styles.image}
                    />
                </span>
            </p>
        </div>
    );
};

const styles = {
    container: {
        padding: '40px 20px',
        backgroundColor: '#100f11',
        textAlign: 'center',
    },
    heading: {
        fontSize: '2rem',
        marginBottom: '20px',
        color: '#e8e5ea',
    },
    text: {
        fontSize: '1.2rem',
        color: '#e8e5ea',
    },
    inlineImage: {
        display: 'inline-block',
        marginLeft: '5px',
        verticalAlign: 'middle',
        position: 'relative',
    },
    image: {
        height: '1.5em',
        width: 'auto',
        position: 'relative',
        top: '-3px',
        maxHeight: '20px', // We make this adjustable so we can make it look seamless with the other text in the future.
        verticalAlign: 'middle',
    },
};

export default ContactUs;
